<template>
	<div class="list">
		<h1>list page</h1>
	</div>
</template>

<script>
export default {
	name: 'ListPages',
};
</script>

<style>

</style>
